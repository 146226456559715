import * as ReactFeather from "react-feather";
import React, { useState } from "react";
import { boxShadows, colours } from "../../styles";
import _ from "lodash";
import { money } from "../../helpers/format";
import styled from "styled-components";
import Tooltip from "rc-tooltip";

const Wrapper = styled.div`
	position: relative;
	width: ${(props) => props.width || "170px"};

	svg {
		position: absolute;
		${(props) => props.align}: 8px;
		top: 10px;
		z-index: 1;
	}

	span {
		position: absolute;
		${(props) => props.align}: 4px;
		top: 8px;
		z-index: 1;
		font-weight: normal;
	}

	input {
		background: ${(props) =>
			props.id ? colours.offWhite : colours.background};
		border: 0;
		border-radius: 4px;
		box-shadow: ${boxShadows.light};
		color: ${(props) =>
			props.disabled ? colours.lightGrey : colours.darkGrey};
		cursor: pointer;
		font-size: 18px;
		height: 40px;
		line-height: 24px;
		outline: 0;
		padding: 8px ${(props) => (props.align === "right" ? "36px" : "8px")} 8px
			${(props) => (props.align === "left" ? "36px" : "8px")};
		position: relative;
		text-align: left;
		width: 100%;
	}

	.tip {
		position: absolute;
		top: 8px;
		left: 260px;
		cursor: pointer;
	}
`;

export default function InputWithIcon(props) {
	const iconMapper = {
		DollarSign: {
			icon: ReactFeather.DollarSign,
			formatter: (value) => ({
				parsed: parseFloat(parseFloat(value).toFixed(2)),
				formatted: !_.isNaN(value) ? money(value, false, false) : "",
			}),
		},
	};
	let Icon = null;
	let model = null;
	//using icon
	model = iconMapper[props.icon];
	//look for icon and use no formatter
	if (!model) {
		model = {
			// eslint-disable-next-line
			icon: props.icon ? ReactFeather[props.icon] : null,
			formatter: (value) => ({ parsed: value, formatted: value }),
		};
	}
	Icon = model.icon;
	let initialValue =
		props.value === null && props.allowNull
			? "" //empty string if value is null and the input is allowed to accept nulls
			: model
			? model.formatter(props.value).formatted
			: !_.isNaN(props.value)
			? props.value
			: "";
	const [value, setValue] = useState(initialValue);
	return (
		<Wrapper disabled={props.disabled} align={props.align} width={props.width}>
			{Icon && (
				<Icon
					size={20}
					color={props.disabled ? colours.lightGrey : colours.darkGrey}
				/>
			)}

			<input
				type={props.type || "text"}
				placeholder={props.placeholder || ""}
				name="input"
				value={value}
				onChange={(event) => {
					if (model) {
						if (props.allowNull && _.isNaN(parseInt(event.target.value))) {
							props.onFormat?.("");
							setValue("");
							return;
						} else {
							const result = model.formatter(event.target.value);
							if (result) {
								props.onFormat?.(result.parsed);
							}
						}
					} else {
						props.onFormat?.(event.target.value);
					}

					setValue(event.target.value);
				}}
				onBlur={(event) => {
					if (model) {
						if (props.allowNull && _.isNaN(parseInt(event.target.value))) {
							props.onFormat?.("");
							setValue("");
						} else {
							const result = model.formatter(event.target.value);
							if (result) {
								setValue(result.formatted);
								props.onFormat?.(result.parsed);
							}
						}
					}
				}}
			/>
			{props.tip && (
				<Tooltip overlay={props.tip} placement="right">
					<ReactFeather.HelpCircle className="tip" />
				</Tooltip>
			)}
		</Wrapper>
	);
}
